<template>
  <DataTable
    class="p-datatable-sm"
    :value="data"
    :loading="loading"
    :paginator="true"
    :rows="50"
    :rowsPerPageOptions="[5, 10, 20, 50]"
  >
    <template #empty>
      <div
        class="flex justify-content-center flex-column align-items-center my-6"
      >
        <i class="pi pi-inbox text-6xl mb-4 text-gray-700"> </i>
        <span class="text-gray-500">Нет данных</span>
      </div>
    </template>
    <Column field="service" header="Услуги" bodyClass="py-2">
      <template #body="slotProps">
        <!-- {{ slotProps.data.service.map((el) => el.name).join(" , ") }} -->
        <Tag
          class="border-1 my-1 border-gray-500"
          :class="{ 'border-primary': slotProps.data.service === null }"
          style="background: transparent"
        >
          <span v-if="slotProps.data.service !== null" class="text-gray-900">
            {{
              slotProps.data.service ? slotProps.data.service.name : ""
            }}</span
          >
          <span v-else class="text-gray-900">
            {{ slotProps.data.cat ? slotProps.data.cat.name : "" }}</span
          >
          <span
            class="ml-1"
            :class="{
              'text-green-700': slotProps.data.type_limiter.pk === 1,
              'text-red-700': slotProps.data.type_limiter.pk === 2,
            }"
            >{{ slotProps.data.type_limiter.name }}
          </span>
        </Tag>
      </template>
    </Column>
    <Column
      v-if="$store.state.curUser && $store.state.curUser.edit_card && isEdit"
    >
      <template #body="slotProps">
        <ConfirmPopup />
        <Button
          :loading="false"
          icon="pi pi-trash"
          class="p-button-danger p-button-text p-button-rounded p-0"
          severity="danger"
          text
          rounded
          aria-label="Cancel"
          @click="delLimiters($event, slotProps.data.id_from)"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script>
import { delLimiter } from "../api/limiter";
export default {
  name: "CardDataLimiter",
  props: {
    data: {
      type: Array,
      requaried: true,
      default: [],
    },
    cardNumber: {
      type: Number,
      requaried: true,
    },
    isEdit: {
      type: Boolean,
      requaried: true,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    updateHistoryCard() {
      this.$emit("updateHistory", {});
    },
    delLimiters(event, id) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Вы действительно хотите удалить ограничитель?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          delLimiter(this, id);
          this.loading = true;
        },
      });
    },
  },
};
</script>

<style>
</style>