<template>
  <Button
    label="Добавить ограничитель"
    class="w-full h-full p-button-sm px-0 flex justify-content-center"
    @click="openDialog"
  ></Button>

  <Dialog
    v-model:visible="display"
    class="shadow-6"
    contentClass="h-screen sm:h-auto"
    :style="{ width: '80vw' }" 
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
    :modal="true"
  >
    <template #header>
      <b class="text-xl mb-3">Добавить ограничитель</b>
    </template>
    <div class="px-4 pt-4 md:px-2 lg:px-2">
      <div class="grid">
        <div v-if="cardType === 2" class="col-12 limters-message">
          <div class="limiters-msg">
            <b>Внимание!</b> <br />
            Добавьте лимит на открываемый товарный ограничитель.
            В&nbsp;противном случае открываемая категория или услуга будет
            безлимитной в&nbsp;рамках остатка на счете.
          </div>
        </div>
        <div class="col-12 lg:col-2">
          <p
            class="alertLimit m-0 shadow-4 p-3 border-round text-white line-height-3 mr-3"
          >
            При выборе <b>Категории</b>, будут добавлены
            <u>все услуги этой категории</u>
          </p>
        </div>
        <div class="col-12 lg:col-10">
          <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12 md:col-6">
              <label for="nickname" class="font-medium text-900"
                >Категория</label
              >
              <MultiSelect
                v-model="selectCats"
                :options="serviceLimiters"
                :filter="true"
                class="w-full shadow-1"
                :showToggleAll="false"
                display="chip"
                optionLabel="name"
                placeholder="Выбрать категорию"
                @change="filterServices($event)"
              />
            </div>
            <div class="field mb-4 col-12 md:col-6">
              <label for="email" class="font-medium text-900">Услуга</label>
              <MultiSelect
                class="w-full shadow-1"
                :filter="true"
                v-bind:disabled="disabledServices"
                v-model="selectServices"
                :options="optionsServices"
                :showToggleAll="false"
                :selectionLimit="countService"
                optionLabel="name"
                display="chip"
                placeholder="Выбрать услугу"
                @change="setServices($event)"
              />
            </div>
            <div class="field mb-4 col-12 md:col-6">
              <label for="email" class="font-medium text-900"
                >Тип ограничителя</label
              >
              <Dropdown
                class="shadow-2"
                v-model="limterOptions.limiterType"
                :options="typeLimiter"
                optionLabel="name"
                placeholder="Выберите ограничитель"
                optionValue="pk"
              />
            </div>
            <div class="col-12">
              <Tag
                v-for="(item, index) in selectCats"
                :key="index"
                class="my-1 mr-1 border-1 border-gray-500 text-white shadow-2"
                >{{ item.name }}
              </Tag>
              <Tag
                class="my-1 mr-1 border-1 border-gray-500 text-gray-900 shadow-2"
                style="background: transparent"
                v-for="s in selectServices"
                :key="s.pk"
                >{{ s.name }}
              </Tag>
            </div>
            <div class="col-12">
              <Button
                @click="addLimiter"
                label="Добавить"
                :loading="isLoading"
                class="w-auto mt-3 shadow-4"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template> 
  <script>
import { getToolsLimiter, addLimiter } from "@/api/limiters";

export default {
  name: "AddLimiters",
  props: {
    card: {
      type: Object,
      required: true,
    },
    services: {
      type: Array,
      required: true,
    },
    cardNumber: {
      type: String,
      required: true,
    },
    cardType: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      display: false,
      typeLimiter: this.card.card_type.type_limiter,
      selectTypeLimiter: null,
      serviceLimiters: [],
      selectedValue: null,
      limterOptions: {
        limiterCode: null,
        limiterCat: null,
        limiterType: null,
      },
      selectServices: [],
      selectCats: [],
      optionsServices: [],
      disabledServices: true,
      countService: null,
    };
  }, 
  methods: {
    openDialog() {
      getToolsLimiter(this);
      this.display = true;
      this.limterOptions.limiterCode = null;
      this.limterOptions.limiterCat = null;
      this.limterOptions.limiterType = null;
      this.optionsServices = [];
      this.serviceLimiters = [];
      this.selectCats = [];
      this.selectServices = [];
      this.disabledServices = true;
      if (!this.card.card_type.many_services) {
        this.countService = 1;
      }
    },
    parentAddLimiter(data) {
      this.$emit("updateDataLimiter", { limit: data });
    },
    addLimiter() {
      if (
        this.limterOptions.limiterCode !== null &&
        this.limterOptions.limiterCat !== null &&
        this.limterOptions.limiterType !== null
      ) {
        this.isLoading = true;
        addLimiter(this);
      } else {
        this.$toast.add({
          severity: "warn",
          summary: `Пожалуйста заполните обязательные поля`,
          detail: `Категория, Тип оганичителя`,
          life: 5000,
        });
      }
    },
    observeCat(event) {
      console.log(event.value[0].code);
      this.limterOptions.limiterCat = event.value[0].iscat;
    },
    setServices(event) {
      this.selectServices = [];
      if (event.value.length !== 0) {
        this.selectServices.push(event.value[event.value.length - 1]);
        this.limterOptions.limiterCode =
          event.value[event.value.length - 1].code;
        this.limterOptions.limiterCat = false;
      } else {
        this.limterOptions.limiterCat = true;
        this.limterOptions.limiterCode = this.selectCats[0].code;
      }
    },
    filterServices(event) {
      console.log(event.value.length);
      this.selectCats = [];
      this.selectServices = [];
      this.disabledServices = true;
      if (event.value.length > 0) {
        this.selectCats.push(event.value[event.value.length - 1]);
        this.optionsServices = event.value[event.value.length - 1].services;
        this.disabledServices = false;
        this.limterOptions.limiterCode =
          event.value[event.value.length - 1].code;
        this.limterOptions.limiterCat = true;
      }
    },
  },
  watch: {
    selectCats(value) {
      if (!value.length) {
        this.limterOptions.limiterCode = null;
      }
    },
  },
};
</script>
  
  <style lang="scss">
.p-dialog-header {
  justify-content: space-between !important;
}

.p-multiselect {
  border: 1px solid #ced4da !important;
}

.alertLimit {
  background: var(--baseLight);
}

.addLimitButton {
  justify-content: center !important;
}

.limters-message {
  .p-message-text {
    margin-left: 1rem;
  }
}

.limiters-msg {
  background: #fff2e2;
  border: solid #cc892540;
  border-width: 1px;
  color: #cc8925;
  padding: 1rem;
}

// li[aria-label]:not(li[aria-label^="Категория "]) {
//   padding-left: 3.5rem !important;
// }
</style>