<template>
  <DataTable
    :value="data"
    :loading="loading"
    class="p-datatable-sm"
    :lazy="true"
    :paginator="true"
    :rows="rows"
    :totalRecords="totalRecords"
    @page="onPage($event)"
    :rowsPerPageOptions="[5, 25, 50, 100]"
  >
    <template #header>
      <the-calendar-from-to v-on:setDate="getDateFromComponent">
      </the-calendar-from-to>
    </template>
    <template #empty>
      <div
        class="flex justify-content-center flex-column align-items-center my-6"
      >
        <i class="pi pi-inbox text-6xl mb-4 text-gray-700"> </i>
        <span class="text-gray-500">Нет данных</span>
      </div>
    </template>
    <Column field="date" header="Дата">
      <template #body="slotProps">
        <div
          v-tooltip.bottom="
            slotProps.data.date ? formaterDate(slotProps.data.date) : ''
          "
        >
          {{ slotProps.data.date ? formaterDate(slotProps.data.date) : "" }}
        </div>
      </template></Column
    >
    <Column field="service_name" header="Услуга"></Column>
    <Column field="amount" header="Кол-во"></Column>
    <Column field="price" header="Цена"></Column>
    <div v-if="$store.state.curUser">
      <div v-if="!$store.state.curUser.hide_price">
        <Column field="price_to" header="Цена ТО"></Column>
      </div>
    </div>
    <Column field="sum" header="Сумма"></Column>
    <div v-if="$store.state.curUser">
      <div v-if="!$store.state.curUser.hide_price">
        <Column field="sum_to" header="Сумма ТО"></Column>
      </div>
    </div>
    <Column field="to_address" header="Адрес" style="max-width: 14rem"></Column>
  </DataTable>
</template>

<script>
import TheCalendarFromTo from "../../../components/TheCalendarFromTo.vue";
import { getTransactionsCard } from "../api/trn.js";
import { timeFormater } from "@/handlers/timeFormater";
export default {
  components: { TheCalendarFromTo },
  name: "CardDataTransaction",
  props: {
    cardNumber: {
      type: Number,
      requaried: true,
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      dateStart: null,
      dateEnd: null,
      rows: 100,
      page: 1,
      total_count: null,
    };
  },
  methods: {
    getDateFromComponent(data) {
      this.loading = true;
      this.dateStart = data.start;
      this.dateEnd = data.end;
      getTransactionsCard(this);
    },
    formaterDate(timeValue, hour) {
      return timeFormater(timeValue, hour);
    },
    onPage(event) {
      console.log(event);
      this.loading = true;
      this.page = event.page + 1;
      this.rows = event.rows;
      getTransactionsCard(this);
    },
  },
};
</script>

<style>
</style>