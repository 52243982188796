<template>
  <loading-progress :loading="loading"></loading-progress>
  <MobileSpiner :loading="loading" />
  <!-- <ul
    class="list-none p-3 m-0 surface-section inline-flex align-items-center font-medium overflow-x-auto border-top-1 border-bottom-1 surface-border"
  >
    <li class="px-2">
      <router-link class="text-gray-900" to="/card-n">
        <i class="pi pi-angle-left text-primary text-500 mt-0"></i>
        К списку карт</router-link
      >
    </li>
    <li class="pl-2">
      <i class="pi pi-angle-left text-black text-900 mt-0"></i>
      <b> {{ cardId }}</b>
    </li>
  </ul>  -->
  <div
    class="card-wrapper p-1"
    v-if="card !== null"
    :class="{ ' overlock': loading }"
  >
    <ul
      class="list-none pl-3 m-0 flex font-medium overflow-y-hidden overflow-x-auto border-round mt-2"
    >
      <li class="relative p-2 text-gray-900">
        <a class="text-gray-900 cursor-pointer" @click.prevent="$router.go(-1)">
          <i class="pi pi-angle-left"></i> Назад</a>
      </li>
      <!-- <li class="relative p-2 bg-primary border-round-rightÏ">
        <a class="text-white font-bold white-space-nowrap">{{ cardId }}</a>
      </li> -->
    </ul>
    <div class="grid align-items-center mt-2">
      <div
        class="col-4 hidden lg:flex align-items-center justify-content-center"
      >
        <card-image
          class="shadow-5"
          :cardHolder="
            card.holder !== null ? card.holder.name : 'Без держателя'
          "
          :cardStatus="card.status.name"
          :classCard="
            getCardDesign(card.card_type.code, card.handler_code, 'class')
          "
          :cardNumber="card.number"
          :imgPath="
            getCardDesign(card.card_type.code, card.handler_code, 'imgPath')
          "
          :imgAltText="
            getCardDesign(card.card_type.code, card.handler_code, 'altText')
          "
        />
      </div>
      <div class="col-12 lg:col-8">
        <card class="bg-white shadow-2 border-round">
          <template #header>
            <div
              class="bg-gray-100 py-3 px-2 border-round flex align-items-start sm:align-items-center justify-content-between"
            >
              <div class="">
                <div
                  v-if="$store.state.curUser && $store.state.curUser.edit_card"
                >
                  <div v-if="$store.state.curUser && card.card_type.block">
                    <CardsStatusSwitcher
                      :disabled="
                        ($store.state.curUser.edit_card &&
                          card.status.pk == 2 &&
                          card.card_type.block) ||
                        card.disabled
                      "
                      :valueStatus="card.checkStatus"
                      :tooltip="
                        getTooltipSwitcher(
                          card.checkMess,
                          card.status.pk,
                          $store.state.curUser.edit_card,
                          card.card_type.block
                        )
                      "
                      :dsc="card.status.name"
                      :cardNumber="card.number"
                      @setCardStatus="setStatus"
                    />
                  </div>
                  <div v-else>
                    {{ card.status.name }}
                  </div>
                </div>
                <div v-else>
                  {{ card.status.name }}
                </div>
              </div>
              <div class="flex align-items-center flex-column md:flex-row">
                <!-- <img class="mb-2 mr-0 sm:mr-1 sm:mb-0"
                  style=" width: 3rem"
                  :src="
                    getCardDesign(
                      card.card_type.code,
                      card.handler_code,
                      'cardIcon'
                    )
                  "
                /> -->
                <div class="text-md mb-2 sm:mb-0">
                  <b>Карта:</b> {{ card.number }} |
                </div>

                <div v-if="$store.state.curUser">
                  <div
                    v-if="$store.state.curUser.role == 1"
                    class="ml-1 text-md"
                  >
                    <b> Обработчик:</b> {{ card.handler_name }} |
                  </div>
                </div>

                <div v-if="$store.state.curUser">
                  <div
                    v-if="$store.state.curUser.role == 1"
                    class="ml-1 text-md"
                  >
                    <b> Тип:</b> {{ card.card_type.name }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #content>
            <div class="border-round w-full mt-4">
              <div class="pb-4">
                <!-- <div class="text-xl text-900 font-medium mb-5">Latest News</div> -->
                <ul class="list-none p-0 m-0">
                  <li class="pb-1 border-bottom-1 surface-border">
                    <div class="font-medium text-900 mb-2">Держатель:</div>
                    <div
                      class="line-height-3 text-600"
                      style="max-width: 30rem"
                    >
                      {{ card.holder ? card.holder.name : "Без держателя" }}
                    </div>
                  </li>
                  <li class="py-1 border-bottom-1 surface-border">
                    <div class="font-medium text-900 mb-2">Группа карт:</div>
                    <div
                      class="line-height-3 text-600"
                      style="max-width: 30rem"
                    >
                      {{ card.group ? card.group.name : "Вне группы" }}
                    </div>
                  </li>
                </ul>
                <div
                  v-if="$store.state.curUser && $store.state.curUser.edit_card"
                  class="grid pt-3 align-items-stretch"
                >
                  <div
                    v-if="card.card_type.show_limiter && card.card_type.edit"
                    class="col-12 sm:col-6 lg:col-3"
                  >
                    <add-limiters
                      :cardNumber="card.number"
                      :card="card"
                      :services="card.services"
                      @updateDataLimiter="updaterFromLimiters"
                    />
                  </div>
                  <div
                    v-if="card.card_type.edit"
                    class="col-12 sm:col-6 lg:col-3"
                  >
                    <add-limit
                      :cardNumber="card.number"
                      :card="card"
                      :services="card.services"
                      :card-type="card.card_type"
                      @updateDataLimit="updaterFromLimits"
                    />
                  </div>

                  <div class="col-12 sm:col-6 lg:col-3">
                    <card-holders
                      :company="card.company"
                      v-model:selectHolder="card.holder"
                      :cardNumber="card.number"
                      @updateHistory="updateHistory"
                    />
                  </div>
                  <div class="col-12 sm:col-6 lg:col-3">
                    <card-group
                      :company="card.company"
                      @updateHistory="updateHistory"
                      :cardNumber="card.number"
                      v-model:selectGroup="card.group"
                    />
                  </div>
                </div>
                <div
                  v-if="card.card_type.pk === 3"
                  class="limters-message mt-3"
                >
                  <div class="limiters-msg">
                    <b>Внимание!</b> <br />
                    Остальные услуги по картам <b>Лукойл</b>, которых нет в
                    списке, <b>доступны безлимитно</b>! Чтобы запретить услугу,
                    добавьте её с нулевым лимитом.
                  </div>
                </div>
              </div>
            </div>
          </template>
        </card>
      </div>
    </div>
    <TabView class="mt-4">
      <TabPanel v-if="card.card_type.show_limiter" header="Ограничители">
        <card-data-limiter
          ref="dataLimiters"
          :data="card.limiter"
          :cardNumber="card.number"
          :isEdit="card.card_type.edit"
          @updateHistory="updateHistory"
        />
      </TabPanel>

      <TabPanel header="Лимиты">
        <card-data-limits
          v-model:data="card.limits"
          :cardType="card.card_type.pk"
          :cardTypeCode="card.card_type.code"
          :cardNumber="card.number"
          :isEditValueLimit="card.card_type.edit_limit"
          :isEdit="card.card_type.edit"
          :handlerType="card.handler_type"
          @updateHistory="updateHistory"
          ref="dataLimits"
        />
      </TabPanel>
      <TabPanel header="Транзакции">
        <card-data-transaction :cardNumber="card.number" />
      </TabPanel>
      <TabPanel header="История">
        <CardHistory :cardNumber="card.number" ref="cardHistory"></CardHistory>
      </TabPanel>
    </TabView>
  </div>
  <div v-else-if="!loading" class="surface-section px-4 py-8 md:px-6 lg:px-0">
    <div
      style="
        background: radial-gradient(
          50% 109137.91% at 50% 50%,
          var(--base) 0%,
          rgba(254, 244, 247, 0) 100%
        );
      "
      class="text-center"
    >
      <span
        style="color: var(--base2)"
        class="bg-white font-bold text-8xl inline-block px-8"
        >404</span
      >
    </div>
    <div class="mt-6 mb-5 font-bold text-6xl text-900 text-center">
      Страница не найдена
    </div>
    <p class="text-700 text-3xl mt-0 mb-6 text-center">
      Извините, мы не смогли найти страницу
    </p>
    <div class="justify-content-center flex gap-3">
      <Button
        label="К списку карт"
        icon="pi pi-arrow-left"
        @click="$router.push('/card-n')"
      ></Button>
      <Button
        label="На Главную"
        icon="pi pi-briefcase"
        @click="$router.push('/')"
      ></Button>
    </div>
  </div>
</template>

<script>
import CardHistory from "./components/CardHistory.vue";
import config from "@/configs";
import { getCard } from "./api/card";
import store from "@/store";
import CardImage from "./components/CardImage.vue";
import CardsStatusSwitcher from "./components/CardsStatusSwitcher.vue";
import CardDataLimits from "./components/CardDataLimits.vue";
import CardDataLimiter from "./components/CardDataLimiter.vue";
import CardDataTransaction from "./components/CardDataTransaction.vue";
import CardHolders from "./components/CardHolders.vue";
import CardGroup from "./components/CardGroup.vue";
import AddLimiters from "./components/AddLimiters.vue";
import AddLimit from "./components/AddLimit.vue";

export default {
  name: "TheCard",
  components: {
    CardHistory,
    CardImage,
    CardsStatusSwitcher,
    CardDataLimits,
    CardDataLimiter,
    CardDataTransaction,
    CardHolders,
    CardGroup,
    AddLimiters,
    AddLimit,
  },
  data() {
    return {
      config: config,
      card: null,
      company: this.$route.params.id.split("_")[1],
      loading: true,
      cardId: this.$route.params.id.split("_")[0],
      changer: 0,
    };
  },
  computed: {},
  methods: {
    updateHistory(data) {
      this.$refs.cardHistory.onLazyLoad();
    },
    getCardDesign(code, subCode, setting) {
      let param = this.config.CARD_DISIGN["DEFAULT"][setting];
      if (code) {
        let disignSettings = this.config.CARD_DISIGN;
        let subDisignSettings = this.config.CARD_DISIGN.PPLUS;
        let arrayKey = [];
        let subArrayKey = [];

        for (let value of Object.keys(disignSettings)) {
          arrayKey.push(value);
        }
        for (let value of Object.keys(subDisignSettings)) {
          subArrayKey.push(value);
        }
        arrayKey.forEach((element) => {
          if (element === code) {
            if (code === "PPLUS") {
              subArrayKey.forEach((el) => {
                if (el === subCode) {
                  param = this.config.CARD_DISIGN[code][subCode][setting];
                }
              });
            } else {
              param = this.config.CARD_DISIGN[element][setting];
            }
          }
        });
      }
      return param;
    },
    getTooltipSwitcher(msg, status, edit, block) {
      if (status === 2) {
        return "Для разблокировки карты обратитесь к Вашему менеджеру";
      } else if (!edit && !block) {
        return "У данного типа карты нельзя изменить статус вручную";
      } else {
        return msg;
      }
    },
    setStatus(data) {
      this.card.status.name = data.status;
      this.$refs.cardHistory.onLazyLoad();
    },
    updaterFromLimiters(data) {
      this.card.limiter.push(data.limit);
      this.updateHistory();
    },
    updaterFromLimits(data) {
      // console.log(data);
      this.card.limits.push(data.limit);
      this.$refs.dataLimits.hiddenZeroLimit();
      this.updateHistory();
    },
  },
  mounted() {
    getCard(this);
  },
  created() {},
};
</script>

<style>
.limiters-msg {
  background: #fff2e2;
  border: solid rgba(204, 137, 37, 0.2509803922);
  border-width: 1px;
  color: #cc8925;
  padding: 1rem;
}
</style>