<template>
  <DataTable
    :value="items"
    responsiveLayout="stack"
    :loading="loading"
    :lazy="true"
    :paginator="true"
    :rows="rows"
    :totalRecords="totalRecords"
    @page="onPage($event)"
    :rowsPerPageOptions="[5, 25, 50, 100]"
    class="p-datatable-sm"
  >
    <template #empty>
      <div
        class="flex justify-content-center flex-column align-items-center my-6"
      >
        <i class="pi pi-inbox text-6xl mb-4 text-gray-700"> </i>
        <span class="text-gray-500">Нет данных</span>
      </div>
    </template>
    <Column field="event_type" header="Действие"></Column>
    <Column field="changed_to" header="Событие"></Column>
    <Column field="datetime" header="Дата">
      <template #body="slotProps">
        {{ formateData(slotProps.data.datetime) }}
      </template>
    </Column>
    <Column field="user" header="Ответственный"></Column>
  </DataTable>
</template> 

<script>
import { getCardHistory } from "../api/card-history";
import { timeFormater } from "@/handlers/timeFormater";
export default {
  name: "CardHistory",
  props: {
    cardNumber: {
      type: Number,
      requared: true,
    },
  },
  data() {
    return {
      items: null,
      rows: 100,
      page: 1,
      total_count: null, 
      loading: false,
    };
  },
  methods: {
    formateData(value) {
      return timeFormater(value);
    },
    onLazyLoad() {
      this.loading = true;
      getCardHistory(this);
    },
    onPage(event) {
      console.log(event);
      this.page = event.page + 1;
      this.rows = event.rows;
      this.onLazyLoad();
    },
  },
  mounted() {
    this.onLazyLoad();
  },
};
</script>

<style lang="scss">
.p-virtualscroller .p-virtualscroller-loader {
  height: 150px !important;
}
.p-dialog-mask.p-component-overlay.p-component-overlay-enter {
  z-index: 10004 !important;
}

.history-card {
  max-width: 90vw;
  min-width: 60vw;

  .p-dialog-header {
    background: var(--gray-300);
    padding: 1rem;
  }
  .p-dialog-content {
    padding: 0;
  }
}
</style>