import api from "@/api/index";
import store from "@/store";
import {
  setEmoji
} from "@/handlers/emoji";

export function delLimit(that, id) {
  /*Получение и обработка списка карт*/
  api
    .post("/cards/delLimit", {
      card: that.cardNumber,
      id_from: id,
    })
    .then((r) => {
      console.log(r);
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      let idx = that.data.findIndex((el) => el.id_from == id);
      let nameLimit =
        that.dataValue[idx]["cat"] !== null ?
        that.dataValue[idx]["cat"] :
        that.dataValue[idx]["service"].map((el) => el.name).join(" , ");
      that.$toast.add({
        severity: "success",
        summary: `${setEmoji("trash")} Лимит ${nameLimit} удален`,
        life: 3000,
      });
      that.dataValue.splice(idx, 1);
      that.updateHistoryCard();
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji(
              "error"
            )} Ошибка удаления лимита  ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}

export function editLimit(that, data, idx, oldData) {
  console.log(data.limit);
  console.log(oldData.limit);
  console.log(data);
  console.log(oldData);
  api
    .post("/cards/editLimit", {
      // card: that.editTools.cardNumber,
      card: that.cardNumber,
      id_from: data.id_from,
      limit: data.limit,
    })
    .then((r) => {
      console.log(r.data.limit);
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      that.data[idx] = r.data.limit;
      that.$toast.add({
        severity: "success",
        summary: `${setEmoji("access")} Лимит успешно изменен`,
        life: 3000,
      });
      if (that.cardType == 3) {
        that.data[idx].limit == 0 ?
          (that.data[idx].empty = true) :
          delete that.data[idx].empty;
      }
      that.dataValue = that.data;
      if (!that.showZeroLimit) {
        that.dataValue = that.dataValue.filter((el) => el.empty != true);
      }

      that.updateHistoryCard();
    })
    .catch((err) => {
      that.dataValue[idx] = oldData;
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji(
              "error"
            )} Ошибка изменения лимита  ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}


export function extraEditLimits(that, data, idx, oldData) {
  extraDelLimit(that, data, idx, oldData)
}

function extraDelLimit(that, data, idx, oldData) {
  api
    .post("/cards/delLimit", {
      card: that.cardNumber,
      id_from: data.id_from,
    })
    .then((r) => {
      console.log(r);
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
    })
    .catch((err) => {
      that.dataValue[idx] = oldData;
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji(
              "error"
            )} Ошибка редактирования лимита  ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      setTimeout(() => {
        extraAddLimit(that, data, idx, oldData)
      }, 1000)
    });
}

function extraAddLimit(that, data, idx, oldData) {
  api
    .post("/cards/addLimit", {
      card: that.cardNumber,
      code: data.service.map(el => el.code).join(','),
      iscat: data.cat != null ? true : false,
      limit: data.limit ? data.limit : 0,
      periodlimit: data.period_limit.pk,
      typeservice: data.unit.pk,
    })
    .then((r) => {
      // console.log(r.data.limit);
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      console.log(r.message);
      that.dataValue[idx] = r.data.limit
      that.$toast.add({
        severity: "success",
        summary: `${setEmoji("access")} Лимит успешно изменен`,
        life: 3000,
      });
    })
    .catch((err) => {
      that.dataValue[idx] = oldData;
      console.log(err);
      console.log(err.request.responseText);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji(
            "error"
          )} Ошибка редактирования лимита  ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}