import api from "@/api/index";
import { setEmoji } from "@/handlers/emoji";

export function delLimiter(that) {
  api
    .post("/cards/delLimiter", {
      card: that.cardNumber,
      id_from: that.limiter.id_from,
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      that.$toast.add({
        severity: "success",
        summary: `${setEmoji("trash")} Ораничитель удален`,
        life: 2000,
      });
      that.parentDelLimiter(that.idLimiter);
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка удаления ограничителя`,
            detail: `Код ошибки ${err.request.status}`,
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.isLoadingDel = false;
    });
}

export function addLimiter(that) {
  api
    .post("cards/addLimiter", {
      card: that.cardNumber,
      code: that.limterOptions.limiterCode,
      iscat: that.limterOptions.limiterCat,
      type_limiter: that.limterOptions.limiterType,
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      that.$toast.add({
        severity: "success",
        summary: `${setEmoji("access, like")} Ораничитель  добавлен`,
        detail: `ID ${r.data.limiter.id_from}`,
        life: 2000,
      });
      //   let limiter = {
      //     cat: null,
      //     id_from: r.data.id_from,
      //     service: {
      //       code: that.limterOptions.limiterCode[0].code,
      //       name: that.limterOptions.limiterCode[0].name,
      //       pk: that.limterOptions.limiterCode[0].pk,
      //     },
      //     type_limiter: {
      //       name: "Разрешить",
      //       pk: 1,
      //     },
      //   };
      that.parentAddLimiter(r.data.limiter);
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка добавления ограничителя`,
            detail: `Код ошибки ${err.request.status}`,
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.isLoading = false;
      that.display = false;
      that.isLoadingDel = false;
    });
}

export function getToolsLimiter(that) {
  api
    .get("/services/cat", {
      params: {
        card: that.cardNumber,
      },
    })
    .then((r) => {
      that.serviceLimiters = [];
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      console.log(r.data);
      console.log(that.cardType);
      if (that.cardType === 2) {
        r.data.forEach((element) => {
          if (element.code !== "ALL") {
            that.serviceLimiters.push(element);
          }
        });
      } else {
        r.data.forEach((element) => {
          that.serviceLimiters.push(element);
        });
      }

      // that.serviceLimiters = r.data;
      //   that.serviceLimiters = r.data;
      //   r.data.forEach((element, i) => {
      //     let generalIndex = i;
      //     element.key = String(generalIndex);
      //     element.label - element.name;
      //     element.services.forEach((el, i) => {
      //       el.key = generalIndex + "-" + i;
      //     });
      //     that.serviceLimiters.push(element);
      //   });
      //   r.data.forEach((element) => {
      //     that.serviceLimiters.push({
      //       pk: element.pk,
      //       name: "Категория " + element.name,
      //       code: element.code,
      //       iscat: true,
      //     });
      //     element.services.forEach((el) => {
      //       el.iscat = false;
      //       that.serviceLimiters.push(el);
      //     });
      //   });
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка получения периода`,
            detail: `Код ошибки ${err.request.status}`,
            life: 5000,
          });
          break;
      }
    });
}

export function getTypeLimiter(that) {
  api
    .get("/cards/type", {
      params: {
        card_type: that.cardType,
      },
    })
    .then((r) => {
      console.log(r.data.type_limiter);
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      that.typeLimiter = r.data.type_limiter;
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка получения периода`,
            detail: `Код ошибки ${err.request.status}`,
            life: 5000,
          });
          break;
      }
    });
}
