<template>
  <div
    class="card-img flex justify-content-center align-content-center flex-column"
    :class="classCard"
    style="width: 275px; height: 172px"
  >
    <img class="logo" :src="imgPath" :alt="imgAltText" />
    <div
      class="number text-right mt-6 mr-2  text-lg"
      style="letter-spacing: 0.15rem"
    >
      {{ cardNumber }}
    </div>
    <div class="holder mt-4 text-sm" style="letter-spacing: 0.05rem">
      {{ cardHolder }}
    </div>
    <div class="status text-xs" style="letter-spacing: 0.05rem">
      {{ cardStatus }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CardImage",
  props: {
    classCard: {
      type: String,
      requaried: true,
    },
    imgPath: { type: String, requaried: true },
    imgAltText: { type: String, requaried: true },
    cardNumber: {
      type: String,
      requaried: true,
    },
    cardStatus: {
      type: String,
      requaried: true,
    },
    cardHolder: {
      type: String,
      requaried: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.card-img {
  position: relative;
  border-radius: 10px !important;
  .logo {
    position: absolute;
    top: 15px;
    left: 15px;
  }
  .holder {
    position: absolute;
    padding: 0 15px;
    bottom: 15px;
    right: 15px;
    left: 0;
    text-align: right;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .status {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    padding: 0 15px;
    text-align: end;
  }
  &.rosn {
    background: rgb(251, 214, 119) !important;
    background: linear-gradient(
      90deg,
      rgba(251, 214, 119, 1) 28%,
      rgba(251, 214, 119, 0.6197829473586309) 67%,
      rgba(248, 227, 95, 0.7626400902157738) 96%
    ) !important;

    img {
      width: 50px;
    }

    .p-button.p-button-link {
      color: inherit !important;
      text-decoration: underline;
    }
  }

  &.gpn {
    color: #fff !important;
    background: rgb(15, 44, 64) !important;
    background: linear-gradient(
      90deg,
      rgba(15, 44, 64, 1) 15%,
      rgba(0, 59, 164, 1) 78%
    ) !important;

    img {
      width: 70px;
    }

    .p-button.p-button-link {
      color: inherit !important;
      text-decoration: underline;
    }
  }

  &.pp {
    color: #fff !important;
    background: rgb(58, 57, 133) !important;
    background: linear-gradient(
      90deg,
      rgba(58, 57, 133, 1) 15%,
      rgba(52, 153, 255, 1) 78%
    ) !important;

    img {
      width: 100px;
    }

    .p-button.p-button-link {
      color: inherit !important;
      text-decoration: underline;
    }
  }

  &.dt {
    color: #fff !important;
    // background: #7B7B7B !important;
    background: linear-gradient(89.8deg, #2a2a2a 0.16%, #858585 87.51%);

    img {
      width: 70px;
    }

    .p-button.p-button-link {
      color: inherit !important;
      text-decoration: underline;
    }
  }

  &.blue {
    color: #fff !important;
    // background: #7B7B7B !important;
    background: linear-gradient(269.9deg, #00d4ff 0.09%, #001678 99.07%);
    // background: url('https://res.cloudinary.com/dk5qwadv5/image/upload/v1663174889/card-blue_3_v12xvj.png');
    background-position: center center;
    background-size: cover;

    img {
      width: 80px;
    }

    .p-button.p-button-link {
      color: inherit !important;
      text-decoration: underline;
    }
  }

  &.purple {
    color: #fff !important;
    // background: #7B7B7B !important;
    background: linear-gradient(269.9deg, #6b1b93 0.09%, #000004 99.07%);
    // background: url('https://res.cloudinary.com/dk5qwadv5/image/upload/v1663174889/card-purple_3_i8ghry.png');
    background-position: center center;
    background-size: cover;

    img {
      width: 70px;
    }

    .p-button.p-button-link {
      color: inherit !important;
      text-decoration: underline;
    }
  }

  &.luk {
    color: #fff !important;
    background: rgb(182, 22, 47);
    background: linear-gradient(
      90deg,
      rgba(182, 22, 47, 1) 27%,
      rgba(237, 26, 58, 0.7) 76%
    );
    background-position: center center;
    background-size: cover;

    img {
      width: 40px;
    }

    .p-button.p-button-link {
      color: inherit !important;
      text-decoration: underline;
    }
  }

  &.tatneft {
    color: #fff !important;
    background: rgb(1, 89, 58);
    background: linear-gradient(
      45deg,
      rgba(1, 89, 58, 1) 21%,
      rgba(3, 162, 107, 1) 83%
    );
    background-position: center center;
    background-size: cover;

    img {
      margin-top: 0.5rem;
      width: 100px;
    }

    .p-button.p-button-link {
      color: inherit !important;
      text-decoration: underline;
    }
  }
}
</style>