<template>
  <layoutBase class="p-0">
    <TheCard> </TheCard>
  </layoutBase>
</template>
  
  <script>
import TheCard from "../modules/card";

export default {
  components: { TheCard },
};
</script>
  
  <style>
</style> 