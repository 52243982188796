import api from "@/api/index";
import {
  setEmoji
} from "@/handlers/emoji";

export function getHolderList(that) {
  /*Получение и обработка списка транзакций*/

  api
    .get("holder/list", {
      params: {
        company: that.company,
      },
    })
    .then((response) => {
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      console.log(response.data);
      if (response.data.length) {
        that.list = response.data;
      }
    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}

export function delHolder(that, pk) {
  /*Получение и обработка списка транзакций*/

  api
    .post("holder", {
      company: that.company,
      action: "del",
      pk: pk,
    })
    .then((response) => {
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      let idx = that.list.findIndex((el) => el.pk == pk);
      that.list.splice(idx, 1);
      that.$toast.add({
        severity: "success",
        summary: `Держатель`,
        detail: `${setEmoji("trash")}  Успешно удален`,
        life: 1500,
      });
      that.updateHistoryCard();
      if ((pk = that.selectHolder.pk)) {
        that.updateHolderInCard(null);
      }
    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}

export function addHolder(that) {
  /*Получение и обработка списка транзакций*/

  api
    .post("holder", {
      company: that.company,
      action: "add",
      name: that.newHolderName && that.newHolderName != "" ?
        that.newHolderName :
        `Новый держатель-${that.list.length}`,
    })
    .then((response) => {
      console.log(response);
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      that.list.push(response.data);
      that.$toast.add({
        severity: "success",
        summary: `Держатель`,
        detail: `${setEmoji("access")} Успешно добавлен`,
        life: 1500,
      });
      if (that.addNow) {
        that.selectedHolder = response.data;
        that.onRowSelect(response);
      }
      that.newHolderName = null;
      that.updateHistoryCard();
    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          let key = Object.keys(JSON.parse(err.request.responseText));
          let msg = JSON.parse(err.request.responseText)[key];
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: `${msg}`,
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      setTimeout(() => {
        that.loading = false;
      }, 3000);
    });
}

export function setHolder(that, pk) {
  /*Получение и обработка списка транзакций*/

  api
    .post("holder", {
      company: that.company,
      action: "set",
      pk: pk,
      card: that.cardNumber,
    })
    .then((response) => {
      console.log(response);
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      that.$toast.add({
        severity: "success",
        summary: `Держатель`,
        detail: `${setEmoji("access")} Успешно привязан к карте ${
          that.cardNumber
        }`,
        life: 1500,
      });
      that.updateHistoryCard();
      that.updateHolderInCard(that.selectedHolder);
      getHolderList(that);
      //   that.$emit("update:selectHolder", that.selectedHolder);
    })
    .catch((err) => {
      that.selectedHolder = that.selectHolder;
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      setTimeout(() => {
        that.loading = false;
      }, 3000);
    });
}

export function editHolder(that, idx, oldData, newData) {
  let params = {
    company: that.company,
    action: "edit",
    pk: newData.pk,
  }
  Object.keys(newData).forEach(el => {
    if (newData[el] !== oldData[el]) {
      params[el] = newData[el]
    }
  })
  api
    .post("holder", params)
    .then((response) => {
      console.log(response);
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      that.list[idx] = response.data;
      that.$toast.add({
        severity: "success",
        summary: `Держатель`,
        detail: `${setEmoji("access")} Успешно отредактирован`,
        life: 1500,
      });
      if ((newData.pk = that.selectHolder.pk)) {
        that.updateHolderInCard(newData);
      }
      that.updateHistoryCard();
    })
    .catch((err) => {
      that.list[idx] = oldData;
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          let key = Object.keys(JSON.parse(err.request.responseText));
          let msg = JSON.parse(err.request.responseText)[key];
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: `${msg}`,
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      setTimeout(() => {
        that.loading = false;
      }, 3000);
    });
}

export function unbindHolder(that) {
  /*Получение и обработка списка транзакций*/

  api
    .post("holder", {
      company: that.company,
      action: "set",
      card: that.cardNumber,
    })
    .then((response) => {
      console.log(response);
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      that.$toast.add({
        severity: "success",
        summary: `Держатель`,
        detail: `${setEmoji("access")} Успешно отвязан от карты ${
          that.cardNumber
        }`,
        life: 1500,
      });
      that.selectedHolder = null;
      that.updateHolderInCard(that.selectedHolder);
      that.updateHistoryCard();
      getHolderList(that);
      //   that.$emit("update:selectHolder", that.selectedHolder);
    })
    .catch((err) => {
      that.selectedHolder = that.selectHolder;
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: statusText,
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      setTimeout(() => {
        that.loading = false;
      }, 3000);
    });
}