<template>
  <DataTable
    :loading="loading"
    class="p-datatable-sm"
    :value="dataValue"
    dataKey="id_from"
    @row-edit-save="onRowEditSave"
    v-model:editingRows="editingRows"
    editMode="row"
    ref="lol"
    :paginator="true"
    :rows="50"
    :rowsPerPageOptions="[5, 10, 20, 50]"
  >
    <template v-if="cardType === 3" #header>
      <div class="px-2 limters-message">
        <div
          v-if="data.filter((el) => el.limit == 0).length > 0"
          class="field-checkbox mt-2"
        >
          <Checkbox v-model="showZeroLimit" :binary="true" />
          <div
            v-badge="howZeroLimits"
            class="flex justify-content-between ml-2 counter-limit p-overlay-badge"
          >
            Показать нулевые лимиты
            <!-- <span class="block"
              >Кол-во:
              <b>{{ data.filter((el) => el.limit == 0).length }}</b></span
            > -->
          </div>
        </div>
      </div>
    </template>
    <template #empty>
      <div
        class="flex justify-content-center flex-column align-items-center my-6"
      >
        <i class="pi pi-inbox text-6xl mb-4 text-gray-700"> </i>
        <span class="text-gray-500">Нет данных</span>
      </div>
    </template>
    <Column field="service" header="Услуги" class="max-w-10rem">
      <template #body="slotProps">
        <!-- {{ slotProps.data.service.map((el) => el.name).join(" , ") }} -->
        <div class="inline">
          <Tag
            v-if="slotProps.data.cat !== null"
            class="my-1 border-1 border-primary mr-1"
            style="background: transparent"
          >
            <span class="text-gray-900">{{
              slotProps.data.cat !== null ? slotProps.data.cat.name : ""
            }}</span>
          </Tag>
          <span v-if="slotProps.data.service.length !== 0">
            <Tag
              v-for="(s, i) in slotProps.data.service"
              :key="i"
              class="my-1 border-1 border-gray-500 mr-1"
              style="background: transparent"
            >
              <span class="text-gray-900">{{ s.name }}</span>
            </Tag>
          </span>
        </div>
      </template>
    </Column>
    <Column field="limit" header="Количество" style="width: 20%">
      <template #editor="{ data, field }">
        <InputNumber
          v-model="data[field]"
          @keydown.enter="enter(data[field])"
          @keydown.esc="cancel(data[field])"
        /> </template
    ></Column>
    <Column
      v-if="cardTypeCode == 'RN' || cardTypeCode == 'PPLUS'"
      field="cur_limit"
      header="Остаток"
    ></Column>
    <Column field="period_limit.name" header="Период"></Column>
    <Column field="unit.name" header="Единица"></Column>
    <div
      v-if="$store.state.curUser && $store.state.curUser.edit_card && isEdit"
    >
      <Column
        v-if="isEditValueLimit || this.handlerType == 'GlTruck'"
        header="Управление"
        ref="roeEd"
        :rowEditor="true"
        style="width: 10%; min-width: 8rem; text-align: right"
        bodyStyle="text-align:center"
      >
      </Column>
      <Column v-else header="Управление">
        <template #body>
          <div class="bold text-medium text-centerr">
            Нельзя изменить лимит вручную
          </div>
        </template>
      </Column>
    </div>
    <Column
      field="id_from"
      class="w-2rem"
      v-if="$store.state.curUser && $store.state.curUser.edit_card && isEdit"
    >
      <template #body="slotProps">
        <ConfirmPopup />
        <Button
          :loading="false"
          icon="pi pi-trash"
          class="p-button-danger p-button-text p-button-rounded"
          severity="danger"
          text
          rounded
          aria-label="Cancel"
          @click="delLimit($event, slotProps.data.id_from)"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script>
import { delLimit, editLimit, extraEditLimits } from "../api/limits";
export default {
  name: "CardDataLimits",
  props: {
    data: {
      type: Array,
      requaried: true,
      default: [],
    },
    cardNumber: {
      type: Number,
      requaried: true,
    },
    cardType: {
      type: Number,
      requaried: true,
    },
    cardTypeCode: {
      type: String,
      requaried: true,
    },
    isEditValueLimit: {
      type: Boolean,
      requaried: true,
      default: true,
    },
    isEdit: {
      type: Boolean,
      requaried: true,
      default: false,
    },
    handlerType: {
      type: String,
      requaried: true,
    },
  },
  data() {
    return {
      showZeroLimit: false,
      dataValue: this.data,
      changeValue: true,
      editingRows: [],
      loading: false,
      searchString: "",
    };
  },
  computed: {
    howZeroLimits() {
      return this.data.filter((el) => el.empty).length;
    },
  },
  methods: {
    updateHistoryCard() {
      this.$emit("updateHistory", {});
    },
    onRowEditSave(event) {
      let { newData, index, data } = event;
      console.log(newData);
      console.log();
      this.dataValue[index] = newData;
      if (JSON.stringify(newData) != JSON.stringify(data)) {
        this.loading = true;
        if (this.handlerType == "GlTruck") {
          extraEditLimits(this, newData, index, data);
        } else {
          editLimit(this, newData, index, data);
        }
      }

      //       handler_code[]: expcard-gltruck
      // handler_code[]: dtteh-gltrack
    },
    delLimit(event, id) {
      console.log(event);
      this.$confirm.require({
        target: event.currentTarget,
        message: "Вы действительно хотите удалить лимит?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          delLimit(this, id);
          this.loading = true;
        },
      });
    },
    enter(event) {
      console.log(this.$refs.roeEd);
      this.$refs.lol.onRowEditInit(event);
    },
    cancel(event) {
      console.log(this.$refs.roeEd);
      this.$refs.lol.onRowEditCancel(event);
    },
    filterCompany() {
      let companies = this.dataValue;
      let searchString = this.searchString;

      if (!searchString) {
        return companies;
      }

      searchString = searchString.trim().toLowerCase();

      companies = companies.filter(function (item) {
        if (item.service.name.toLowerCase().indexOf(searchString) !== -1) {
          return item;
        }
        if (item.cat.name.toLowerCase().indexOf(searchString) !== -1) {
          return item;
        }
      });
      return companies;
    },
    hiddenZeroLimit() {
      if (this.cardType == 3) {
        this.dataValue = this.data;
        this.data.map((el) => (el.limit == 0 ? (el.empty = true) : null));
        this.dataValue = this.dataValue.filter((el) => !el.empty);
      }
    },
  },
  mounted() {
    this.hiddenZeroLimit();
    // this.data.sort((a, b) => b.limit - a.limit);
  },
  watch: {
    showZeroLimit(val) {
      if (val) {
        this.dataValue = this.data;
      } else {
        this.dataValue = this.dataValue.filter((el) => el.empty != true);
      }
    },
  },
};
</script>

<style lang="scss">
.counter-limit {
  .p-badge.p-component {
    margin-right: -8px;
    margin-top: -6px;
    font-size: 1rem !important;
  }
}
</style>