import api from "@/api/index";
import { setEmoji } from "@/handlers/emoji";

export function getTransactionsCard(that) {
  /*Получение и обработка списка транзакций*/

  api
    .get("trn/list", {
      params: {
        dateStart: that.dateStart,
        dateEnd: that.dateEnd,
        page: that.page,
        rows: that.rows,
        company: 0,
        card: that.cardNumber,
      },
    })
    .then((response) => {
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      if (response.data.length) {
        that.totalRecords = response.data[0]["total_count"];
        that.data = response.data;
      }
    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}
