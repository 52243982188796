import api from "@/api/index";
import { setEmoji } from "@/handlers/emoji";

export function getCardHistory(that) {
  api
    .get("cards/cardHistory", {
      params: {
        card_number: [that.cardNumber],
        page: that.page,
        rows: that.rows,
      },
    })
    .then((r) => { 
      if (r.data.length) {
        that.items = r.data;
        that.totalRecords = r.data[0]["total_count"];
        // that.total_count = r.data[0]["total_count"];
      }
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}
