import api from "@/api/index";
import { setEmoji } from "@/handlers/emoji";

export function delLimiter(that, id) {
  api
    .post("/cards/delLimiter", {
      card: that.cardNumber,
      id_from: id,
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      let idx = that.data.findIndex((el) => el.id_from == id);
      that.$toast.add({
        severity: "success",
        summary: `${setEmoji("trash")} Ораничитель ${
          that.data[idx].service ? that.data[idx].service.name : that.data[idx].cat.name
        } удален`,
        life: 2000,
      });
      that.data.splice(idx, 1);
      that.updateHistoryCard();
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка удаления ограничителя`,
            detail: `Код ошибки ${err.request.status}`,
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}
