<template>
  <Button
    label="Добавить лимит"
    class="w-full h-full p-button-sm px-0 flex justify-content-center"
    @click="openDialog"
  ></Button>

  <Dialog
    v-model:visible="display"
    class="shadow-6"
    contentClass="h-screen sm:h-auto"
    :style="{ width: '80vw' }"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
    :modal="true"
  >
    <template #header> <b class="text-xl mb-3">Добавить лимит</b> </template>
    <div class="px-4 pt-4 md:px-2 lg:px-2">
      <div class="grid">
        <div class="col-12 lg:col-2">
          <p
            class="alertLimit m-0 shadow-4 p-3 border-round text-white line-height-3 mr-3"
          >
            При выборе только <b>Категории</b>, будут добавлены
            <u>все услуги этой категории</u>
          </p>
        </div>
        <div class="col-12 lg:col-10">
          <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12 md:col-6">
              <label for="nickname" class="font-medium text-900"
                >Категория</label
              >
              <MultiSelect
                v-model="selectCats"
                :filter="true"
                class="w-full shadow-1"
                :options="cats"
                :showToggleAll="false"
                display="chip"
                optionLabel="name"
                placeholder="Выбрать категорию"
                @change="filterServices($event)"
              />
            </div>
            <div class="field mb-4 col-12 md:col-6">
              <label for="email" class="font-medium text-900">Услуга</label>
              <MultiSelect
                class="w-full shadow-1"
                :filter="true"
                v-bind:disabled="disabledServices"
                v-model="selectServices"
                :options="optionsServices"
                :showToggleAll="false"
                :selectionLimit="countService"
                optionLabel="name"
                display="chip"
                placeholder="Выбрать услугу"
                @change="setServices($event)"
                emptyMessage="Лимит будет добавлен на все категории и услуги"
              />
            </div>
            <div class="field mb-4 border-round col-12 md:col-6">
              <label for="state" class="font-medium text-900">Количество</label>
              <InputNumber
                class="n-select shadow-1 border-round"
                v-model="addTools.limit"
                :min="0"
                :max="1000000"
                :step="10"
                showButtons
              />
            </div>
            <div class="field mb-4 col-12 md:col-6">
              <label for="city" class="font-medium text-900"
                >Единица лимита</label
              >

              <Dropdown
                v-model="unit.selectUnit"
                :options="unit.optionUnit"
                v-bind:disabled="disabledServices"
                optionLabel="name"
                optionValue="id"
                placeholder="Единица лимита"
                class="shadow-1"
                @change="setUnit($event)"
              />
            </div>
            <div class="field mb-4 col-12 md:col-6">
              <label for="city" class="font-medium text-900"
                >Период лимита</label
              >
              <Dropdown
                class="shadow-1"
                v-model="period.selectOption"
                :options="period.option"
                optionLabel="name"
                placeholder="Период"
                optionValue="id"
                @change="setPeriod($event)"
              />
            </div>
            <div class="col-12">
              <Tag
                v-for="(item, index) in selectCats"
                :key="index"
                class="my-1 mr-1 border-1 border-gray-500 text-white shadow-1"
                >{{ item.name }}</Tag
              >
              <Tag
                class="my-1 mr-1 border-1 border-gray-500 text-gray-900 shadow-1"
                style="background: transparent"
                v-for="s in selectServices"
                :key="s.pk"
                >{{ s.name }}</Tag
              >
            </div>
            <div class="col-12">
              <Button
                @click="addLimit"
                label="Добавить"
                :loading="isLoading"
                class="w-auto mt-3 shadow-4"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>
  
  <script>
import config from "@/configs";
import store from "@/store";
import { getServices, getServicesCat } from "@/api/services";
import { addLimit, getPeriod } from "@/api/cards_new";

export default {
  name: "AddLimit",
  props: {
    card: {
      type: Object,
      required: true,
    },
    services: {
      type: Array,
      required: true,
    },
    cardNumber: {
      type: String,
      required: true,
    },
    cardType: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      config: config,
      display: false,
      // services: [],
      servicesFiltred: [],
      servicesTools: {
        cardNumber: null,
        selectServices: [],
        selectCount: null,
        selectType: null,
        selectPeriod: null,
        isCat: null,
      },
      editSuccess: true,
      cats: [],
      selectCats: [],
      servicesList: [],
      optionsServices: [],
      selectServices: [],
      disabledServices: true,
      addTools: {
        cat: this.selectCats,
        code: "",
        typeService: "",
        limit: "",
        period: this.period,
        alone: null,
      },
      period: {
        option: [],
        selectOption: null,
      },
      typeLimiter: {},
      unit: {
        unitList: [],
        optionUnit: [],
        selectUnit: null,
      },
      countService: null,
    };
  },
  components: {},
  methods: {
    setServices(event) {
      this.addTools.code = event.value.map((el) => el.code).join(",");
      // console.log(event.value.map((el) => el.code).join(","));
      if (this.selectServices.length === 0) {
        this.addTools.cat = true;
        this.addTools.code = this.selectCats[0].code;
        this.addTools.alone = this.selectCats[0].alone;
      } else {
        this.addTools.cat = false;
        this.addTools.alone = true;
      }
      //   this.servicesTools.cardNumber = event.number;
      //   this.servicesFiltred = this.services.filter(
      //     (el) => el.handler_type === event.handler_type
      //   );
    },
    setUnit(event) {
      this.addTools.typeService = event.value;
    },
    setPeriod(event) {
      this.addTools.period = event.value;
    },
    openDialog() {
      if (this.servicesList.length === 0) {
        getServicesCat(this);
        getPeriod(this);
      }
      if (!this.card.card_type.many_services) {
        this.countService = 1;
      }
      this.display = true;
    },
    parentAddTestLimit(data) {
      this.$emit("updateDataLimit", { limit: data });
    },
    changeServices(e, n) {
      //Подправить имя при выборе типа
      const periodPk = this.card.limits[n].period_limit.pk;
      let findName = config.PERIOD_LIMIT.find((i) => i.pk == periodPk);
      this.card.limits[n].period_limit.name = findName.name;

      const typeServicePk = this.card.limits[n].type_service.pk;
      let findNameService = config.TYPE_SERVICE.find(
        (i) => i.pk == typeServicePk
      );
      this.card.limits[n].type_service.name = findNameService.name;

      let allNames = [];
      let index = 0;

      this.card.limits.forEach((i) => {
        if (i.services !== null && n !== index) {
          i.service.forEach((s) => {
            allNames.push(s.name + "|" + i.period_limit.name);
          });
        }
        index++;
      });

      let indexS = 0;
      if (this.card.limits[n].service !== null) {
        this.card.limits[n].service.forEach((s) => {
          let findName = allNames.findIndex(
            (i) => i == s.name + "|" + this.card.limits[n].period_limit.name
          );
          if (findName > -1) {
            this.$toast.add({
              severity: "warn",
              summary: `Услуга ${s.name} с таким периодом (${this.card.limits[n].period_limit.name}) уже есть`,
              detail: "Поменяйте период, или услугу!",
              life: 5000,
            });
            this.card.limits[n].service.splice(indexS, 1);
          }
          indexS++;
        });
      }
    },
    addLimit() {
      if (
        this.addTools.code.length !== 0 &&
        this.addTools.limit >= 0 &&
        this.addTools.period >= 1 &&
        this.addTools.typeService >= 1 &&
        this.addTools.alone
      ) {
        this.isLoading = true;
        addLimit(this);
        //   getServices(this);
        // this.display = false;
      } else {
        if (!this.addTools.alone) {
          this.$toast.add({
            severity: "warn",
            summary: `Пожалуйста заполните обязательные поля`,
            detail: `Категория, Единица лимита, Услуга, Количество, Период`,
            life: 5000,
          });
        } else {
          this.$toast.add({
            severity: "warn",
            summary: `Пожалуйста заполните обязательные поля`,
            detail: `Категория, Единица лимита, Количество, Период`,
            life: 5000,
          });
        }
      }
    },
    filterServices(event) {
      this.selectServices = [];
      this.selectUnit = [];
      this.selectCats = [];
      this.disabledServices = true;
      // console.log(event);
      // console.log(event.value[0].alone);

      if (event.value.length !== 0) {
        this.selectCats.push(event.value[event.value.length - 1]);
        // console.log(event.value[event.value.length - 1]);
        // console.log(this.selectCats);
        let cat = event.value[event.value.length - 1].code;
        // console.log(cat);
        //   this.addTools.cat = event.value[0];
        this.optionsServices = this.servicesList.filter(
          (el) => el.name === cat
        )[0].servicesList;
        this.unit.optionUnit = this.unit.unitList.filter(
          (el) => el.name === cat
        )[0].unitList;
        // if (cat === "ALL") {
        //   this.disabledServices = true;
        // } else {
        //   this.disabledServices = false;
        // }
        this.disabledServices = false;
      }
      if (this.selectServices.length === 0) {
        this.addTools.cat = true;
        this.addTools.code = event.value[event.value.length - 1].code;
        this.addTools.alone = event.value[0].alone;
      } else {
        this.addTools.cat = false;
        this.addTools.alone = null;
      }
    },
  },
  mounted() {},
  computed: {
    curCompany() {
      return store.state.company.curCompany;
    },
  },
  watch: {
    curCompany() {
      this.selectServices = "";
      this.period.selectOption = "";
      this.selectCats = "";
      this.unit.selectUnit = "";
      (this.addTools.cat = this.selectCats),
        (this.addTools.code = ""),
        (this.addTools.typeService = ""),
        (this.addTools.limit = ""),
        (this.addTools.period = this.period);
    },
    selectCats(value) {
      if (!value.length) {
        // console.log("КАтегория пуста");
        this.addTools.code = "";
        this.addTools.cat = null;
        this.addTools.alone = null;
        this.addTools.typeService = "";
      }
    },
  },
};
</script>
  
  <style lang="scss">
.p-dialog-header {
  justify-content: space-between !important;
}
.p-multiselect {
  border: 1px solid #ced4da !important;
}
.alertLimit {
  background: var(--baseLight);
}
.addLimitButton {
  justify-content: center !important;
}
</style>