import api from "@/api/index";
import store from "@/store";
import { setEmoji } from "@/handlers/emoji";

export function addLimit(that) {
  /*Получение и обработка списка карт*/
  api
    .post("/cards/addLimit", {
      card: that.cardNumber,
      code: that.addTools.code,
      iscat: that.addTools.cat,
      limit: that.addTools.limit ? that.addTools.limit : 0,
      periodlimit: that.addTools.period,
      typeservice: that.addTools.typeService,
    })
    .then((r) => {
      // console.log(r.data.limit);
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      console.log(r.message);
      that.display = false;
      that.parentAddTestLimit(r.data.limit);
      that.$toast.add({
        severity: "success",
        summary: `${setEmoji("access")} Лимит добавлен`,
        life: 3000,
      });
    })
    .catch((err) => {
      console.log(err);
      console.log(err.request.responseText);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: `${JSON.parse(err.request.response).message}`,
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji(
              "error"
            )} Ошибка добавления лимита  ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.isLoading = false;
    });
}

export function delLimit(that) {
  /*Получение и обработка списка карт*/
  api
    .post("/cards/delLimit", {
      card: that.cardNumber,
      id_from: that.limit.id_from,
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));

      that.$toast.add({
        severity: "success",
        summary: `${setEmoji("trash")} Лимит удален`,
        life: 3000,
      });
      that.delLimit();
      that.isLoadingDel = false;
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji(
              "error"
            )} Ошибка удаления лимита  ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    });
}

export function editLimit(that) {
  console.log(console.log(that.limit));
  api
    .post("/cards/editLimit", {
      // card: that.editTools.cardNumber,
      card: that.cardNumber,
      id_from: that.limit.id_from,
      limit: that.limit.limit,
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      that.limit.limit == 0 ? (that.limit.empty = true) : delete that.limit.empty;
      that.$toast.add({
        severity: "success",
        summary: `${setEmoji("access")} Лимит успешно изменен`,
        life: 3000,
      });
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji(
              "error"
            )} Ошибка изменения лимита  ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    });
}

export function getPeriod(that) {
  api
    .get("/cards/type", {
      params: {
        card_type: that.cardType.pk,
      },
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      r.data.period.forEach((element) => {
        that.period.option.push(element);
      });
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji(
              "error"
            )} Ошибка получения периода  ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    });
}

export function getCards(that) {
  if (store.state.company.curCompany === null) {
    // that.loading = false;
    return false;
  }
  /*Получение и обработка списка карт*/
  api
    .get("/cards/list", {
      params: {
        company: store.state.company.curCompany.id,
        // company: that.currentComp,
        rows: that.rowsCount,
        page: that.pageCount,
        search: that.searchString.trim(),
        group: that.labelGroupBtn !== "Группы карт" ? that.labelGroupBtn : null,
        without_group: that.groupTools.without_group,
        with_group: that.groupTools.with_group,
        group_id: that.groupTools.filterName,
      },
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));

      (that.isSearchLoad = false), (that.loadingCard = false);
      if (r.data.length === 0) {
        // that.loading = false;
        that.cards = null;
      }
      that.cards = r.data;
      r.data.forEach((i) => {
        // Cравнить ограничители и лимиты. Если одинаковые, ограничители спрятать
        // i.limiter.sort(compareLim)
        // i.limits.sort(compareLim)
        //i.isCompare = isCompare(i.limiter, i.limits)

        // Добавить информацию для кнопки Разблокировки/Блокировки
        let checkStatus;
        if (i.status.pk === 1) checkStatus = true;
        if (i.status.pk === 2) checkStatus = false;
        if (i.status.pk === 3) checkStatus = false;
        if (i.status.pk === 4) checkStatus = false;
        i.checkStatus = checkStatus;
        if (checkStatus) i.checkMess = "Заблокировать";
        else i.checkMess = "Разблокировать";
        i.disabled = false; // Признак выключения кнопки визуально.
        // console.log(i);
        // that.cards.push(i) если делать подгрузку при скроле
        that.totalItemsCount = i.total_count;
        // that.loading = false;
      });
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji(
              "error"
            )} Ошибка получения списка карт  ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      api
        .get("/holder/list", {
          params: {
            company: store.state.company.curCompany
              ? store.state.company.curCompany.id
              : 1,
          },
        })
        .then((r) => {
          console.info(
            r.statusText + " " + r.status + " " + setEmoji("access")
          );

          that.holdersList = r.data;
        })
        .catch((err) => {
          console.log(err);
          let statusCode = err.request.status;
          let statusText = err.request.statusText;
          console.error(setEmoji("error") + statusCode + " " + statusText);
          switch (statusCode) {
            case 0:
              that.$toast.add({
                severity: "warn",
                summary: `${setEmoji("error")} Нет связи с сервером`,
                detail: "Попробуйте позднее!",
              });
              break;
            case 403:
              that.$toast.add({
                severity: "warn",
                summary: `${setEmoji("lock")} Время сессии истекло`,
                detail: "Требуется авторизация",
                life: 5000,
              });
              that.$router.push("/login");
              break;
            case 404:
              that.$toast.add({
                severity: "error",
                summary: `${setEmoji("error")} Ошибка ${statusCode}`,
                detail: "Запрос не найден",
                life: 5000,
              });
              break;
            default:
              that.$toast.add({
                severity: "error",
                summary: `${setEmoji(
                  "error"
                )} Неизвестная ошибка ${statusCode}`,
                detail: "Попробуйте позднее!",
                life: 5000,
              });
              break;
          }
        })
        .finally(() => {
          that.loading = false;
        });
    });
}

export function setHolder(that) {
  api
    .post("holder", {
      action: "set",
      pk: that.selectHolder.pk,
      company: that.company,
      card: that.cardNumber,
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));

      that.$toast.add({
        severity: "success",
        summary: `${setEmoji("access")} Деражатель привязан`,
        life: 3000,
      });
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    });
}

export function unsetHolder(that) {
  api
    .post("holder", {
      action: "set",
      company: that.company,
      card: that.cardNumber,
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));

      that.$toast.add({
        severity: "success",
        summary: `${setEmoji("access")} Деражатель отвязан`,
        life: 3000,
      });
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    });
}
