import api from "@/api/index";
import store from "@/store";
import { setEmoji } from "@/handlers/emoji";

export function changeStatus(that, i) {
  /* Отправка блокировки на сервер */
  let statusId;
  if (that.status) {
    statusId = 1;
  } else {
    statusId = 3;
  }
  api
    .post("/cards/setStatus", {
      card: that.cardNumber,
      status: statusId,
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      console.log(r.data);
      switch (r.data.status.pk) {
        case 1:
          that.status = true;
          break;
        default:
          that.status = false;
          break;
      }
      let messToast = "";
      if (that.status) {
        that.msg = "Заблокировать";
        messToast = "разблокирована!";
        that.text = "Активна";
      } else {
        that.msg = "Разблокировать";
        messToast = "заблокирована!";
        that.text = "Заблокирована вручную";
      }
      that.$toast.add({
        severity: "success",
        summary: `Карта ${messToast}`,
        detail: `${that.cardNumber}`,
        life: 5000,
      });
      that.createStatus();
    })
    .catch((err) => {
      that.status = !that.status;
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка  ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      setTimeout(() => {
        that.disabled = false;
      }, 5000);
    });
}
